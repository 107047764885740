import classNames from 'classnames'
import React from 'react'

export const Skeleton = React.forwardRef<
  HTMLSpanElement,
  React.HTMLAttributes<HTMLSpanElement> & { containerClassName?: string }
>(({ className, containerClassName, children, ...props }, ref) => (
  <span
    ref={ref}
    className={classNames('relative inline-block rounded-lg bg-gray-100', containerClassName)}
    {...props}
  >
    <span
      className={classNames('absolute inset-0 animate-pulse rounded-lg bg-gray-400', className)}
    />
    <span className="invisible">{children}</span>
  </span>
))
Skeleton.displayName = 'Skeleton'
