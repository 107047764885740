import classNames from 'classnames'
import React, { CSSProperties, useMemo } from 'react'
import { ClientPerson } from '../../clientModels/ClientPerson'
import { ClientPublicUser } from '../../clientModels/ClientPublicUser'
import { personUtils } from '../../modelUtils/personUtils'
import { twFormat } from '../../utils/client/twFormat'
import { MaybeNextImgProps, NextImg } from './Img'
import { cn } from '../../utils/client/cn'

type AvatarBaseProps = {
  size: number
  className?: string
  style?: CSSProperties
}

export type AvatarProps = (
  | Omit<MaybeNextImgProps, 'width' | 'height'>
  | { firstInitial: string; lastInitial?: string }
) &
  AvatarBaseProps

const getFontSize = (size: number) => {
  if (size < 20) {
    return twFormat('text-sm')
  }
  if (size < 30) {
    return twFormat('text-base')
  }
  return twFormat('text-lg')
}

const Avatar: React.FC<AvatarProps> = ({ size, className, style, ...props }) => {
  if ('src' in props) {
    return (
      <div className={cn('relative', className)} style={{ width: size, height: size, ...style }}>
        <NextImg
          fill
          style={{ objectFit: 'cover' }}
          className={classNames('rounded-full')}
          sizes={`${size}px`}
          {...props}
        />
      </div>
    )
  }
  return (
    <div
      className={classNames('flex items-center justify-center rounded-full bg-gray-500', className)}
      style={{ width: size, height: size, ...style }}
    >
      <span className={classNames('font-medium leading-none text-white', getFontSize(size))}>
        {props.firstInitial}
        {props.lastInitial}
      </span>
    </div>
  )
}
Avatar.displayName = 'Avatar'

export const PersonAvatar: React.FC<{ person: ClientPerson } & AvatarBaseProps> = ({
  person,
  ...props
}) => {
  return (
    <Avatar
      {...props}
      {...(person.profilePictureUrl
        ? {
            alt: `Profile picture of ${personUtils.fullName(person)}`,
            src: person.profilePictureUrl,
          }
        : { firstInitial: person.firstName.slice(0, 1), lastInitial: person.lastName.slice(0, 1) })}
    />
  )
}

export const UserAvatar: React.FC<{ user: ClientPublicUser } & AvatarBaseProps> = ({
  user,
  ...props
}) => {
  const initials = useMemo(() => {
    const words = user.displayName.split(' ')
    return [
      words[0].slice(0, 1),
      words.length > 1 ? words[words.length - 1].slice(0, 1) : undefined,
    ] as const
  }, [user.displayName])
  return (
    <Avatar
      {...props}
      {...(user.profilePictureUrl
        ? {
            alt: `Profile picture of ${user.displayName}`,
            src: user.profilePictureUrl,
          }
        : {
            firstInitial: initials[0],
            lastInitial: initials[1],
          })}
    />
  )
}
