import classNames from 'classnames'
import React from 'react'

import { ButtonProps, getCombinedButtonStyles } from './buttonUtils'

export const Button = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps
>(({ className, variant = 'neutral', size = 'normal', ...props }, ref) => (
  <button
    ref={ref}
    className={classNames(getCombinedButtonStyles({ variant, size }), className)}
    {...props}
  />
))

Button.displayName = 'Button'
