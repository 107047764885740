'use client'
import { format as formatDateString } from 'date-fns'
import { useHasMounted } from '../../hooks/useHasMounted'
import { Skeleton } from './Skeleton'

export const ServerRenderedDateTime: React.FC<{ format: string; date: Date }> = ({
  format,
  date,
}) => {
  const hasMounted = useHasMounted()
  if (hasMounted) {
    return <span>{formatDateString(date, format)}</span>
  }
  return <Skeleton>{date.toISOString()}</Skeleton>
}
