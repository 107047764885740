import { PersonPoliticalAffiliationCategory } from '@prisma/client'

export const formatPoliticalAffiliationCategoryAsTag = (
  category: PersonPoliticalAffiliationCategory,
) => {
  switch (category) {
    case PersonPoliticalAffiliationCategory.REPUBLICAN:
      return `(R)`
    case PersonPoliticalAffiliationCategory.LIBERTARIAN:
      return `(L)`
    case PersonPoliticalAffiliationCategory.DEMOCRAT:
      return `(D)`
    case PersonPoliticalAffiliationCategory.INDEPENDENT:
      return `(I)`
    case PersonPoliticalAffiliationCategory.OTHER:
      return ``
  }
}

export const formatPoliticalAffiliationCategory = (
  category: PersonPoliticalAffiliationCategory,
) => {
  switch (category) {
    case PersonPoliticalAffiliationCategory.LIBERTARIAN:
      return `Libertarian`
    case PersonPoliticalAffiliationCategory.REPUBLICAN:
      return `Republican`
    case PersonPoliticalAffiliationCategory.DEMOCRAT:
      return `Democrat`
    case PersonPoliticalAffiliationCategory.INDEPENDENT:
      return `Independent`
    case PersonPoliticalAffiliationCategory.OTHER:
      return ``
  }
}
