import * as Sentry from '@sentry/nextjs'
import { APP_CONFIG } from '../../appConfig'

export const gracefullyError = <Fallback>({
  context,
  msg,
  fallback,
}: {
  msg: string
  fallback: Fallback
  context?: Parameters<typeof Sentry.captureMessage>[1]
}) => {
  if (APP_CONFIG.ENVIRONMENT === 'local') {
    console.log(context)
    throw new Error(msg)
  }
  Sentry.captureMessage(msg, context)
  return fallback
}
