import { gracefullyError } from './gracefullyError'

export const formattedCountryCode = (countryCode: string) => {
  switch (countryCode) {
    case 'US':
      return 'The United States of America'
  }
  return gracefullyError({
    msg: `Invalid country code passed to formattedCountryCode: ${countryCode}`,
    fallback: countryCode,
  })
}
