import { Person } from '@prisma/client'
import { convertToOnlyEnglishCharacters } from '../utils/shared/convertToOnlyEnglishCharacters'

const fullName = (
  person: Pick<Person, 'firstName' | 'middleName' | 'lastName' | 'firstNickname' | 'nameSuffix'>,
) => {
  return `${person.firstNickname || person.firstName} ${person.lastName}${
    person.nameSuffix && ` ${person.nameSuffix}`
  }`
}

const generateSlug = (
  person: Pick<Person, 'firstName' | 'lastName' | 'middleName'> & {
    nameUniquenessModifier?: string
  },
) => {
  return `${convertToOnlyEnglishCharacters(person.firstName).toLowerCase()}${
    person.middleName ? `---${convertToOnlyEnglishCharacters(person.middleName).toLowerCase()}` : ''
  }---${convertToOnlyEnglishCharacters(person.lastName).toLowerCase()}${
    person.nameUniquenessModifier ? `---${person.nameUniquenessModifier}` : ''
  }`.replace(/[^a-zA-Z0-9-]/g, '')
}

const twitterUrl = ({ username }: { username: string }) => `https://twitter.com/${username}`

export type FuzzyMatchResultToPeopleMismatches = { matches: string[]; strategyName: string }

export const fuzzyMatchResultToPeopleList = <T, P extends Person>({
  result,
  people,
  matchPersonStrategies,
}: {
  result: T
  people: P[]
  matchPersonStrategies: Array<{ fn: (person: P) => boolean; strategyName: string }>
}) => {
  const mismatches: Array<FuzzyMatchResultToPeopleMismatches> = []
  for (const { fn, strategyName } of matchPersonStrategies) {
    const matches = people.filter(person => fn(person))
    if (matches.length === 1) {
      return matches[0]
    }
    mismatches.push({
      strategyName,
      matches: matches.map(person => fullName(person)),
    })
  }
  return {
    mismatches,
  }
}

export const personUtils = { fullName, generateSlug, twitterUrl }

export function isStandWithCryptoUserOrganization(user: { twitterUsername: string | null }) {
  return user.twitterUsername?.toLowerCase() === 'standwithcrypto'
}
