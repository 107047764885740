import { PersonRoleCategory, PersonRoleStatus } from '@prisma/client'
import classNames from 'classnames'
import React from 'react'
import { ClientPerson } from '../../clientModels/ClientPerson'
import { ClientPersonRole } from '../../clientModels/ClientPersonRole'
import { formattedState } from '../../utils/shared/formattedState'
import { formattedCountryCode } from '../../utils/shared/formattedCountryCode'
import { getYear, parseISO, subYears } from 'date-fns'
import { gracefullyError } from '../../utils/shared/gracefullyError'
import { formatPoliticalAffiliationCategoryAsTag } from '../../utils/shared/formatPoliticalAffiliationCategory'
import _ from 'lodash'
import { withOrdinalSuffix } from '../../utils/shared/withOrdinalSuffix'

export const FormattedRoleWithinContext: React.FC<{
  role: ClientPersonRole
  color?: 'light' | 'dark'
  person: ClientPerson
}> = ({ role, person, color = 'light' }) => {
  const politicalAffiliationCategoryText =
    person.politicalAffiliationCategory &&
    formatPoliticalAffiliationCategoryAsTag(person.politicalAffiliationCategory)
      ? ` ${formatPoliticalAffiliationCategoryAsTag(person.politicalAffiliationCategory)}`
      : ''
  switch (role.status) {
    case PersonRoleStatus.LOST:
    case PersonRoleStatus.RUNNING_FOR:
    case PersonRoleStatus.DROPPED_OUT:
      switch (role.roleCategory) {
        case 'PRESIDENT':
        case 'VICE_PRESIDENT':
          return (
            <>
              <span
                className={classNames(
                  'font-light',
                  color === 'light' ? 'text-gray-100' : 'text-gray-700',
                )}
              >
                {role.status === PersonRoleStatus.RUNNING_FOR ? 'Running' : 'Previously Ran'} for
                {role.roleCategory === PersonRoleCategory.VICE_PRESIDENT ? 'Vice ' : ''}President of{' '}
              </span>
              <span className="font-semibold">{formattedCountryCode(role.primaryCountryCode)}</span>{' '}
              {politicalAffiliationCategoryText}
            </>
          )
        case 'CONGRESS':
          return (
            <>
              <span
                className={classNames(
                  'font-light',
                  color === 'light' ? 'text-gray-100' : 'text-gray-700',
                )}
              >
                {role.status === PersonRoleStatus.RUNNING_FOR ? 'Running' : 'Previously Ran'} for{' '}
                {role.primaryCountryCode} Congressperson in
                {_.isNumber(Number(role.primaryDistrict))
                  ? ` the ${withOrdinalSuffix(role.primaryDistrict)} district of `
                  : ' '}
                {role.primaryState}
              </span>
              {politicalAffiliationCategoryText}
            </>
          )
        case 'SENATE':
          return (
            <>
              <span
                className={classNames(
                  'font-light',
                  color === 'light' ? 'text-gray-100' : 'text-gray-700',
                )}
              >
                {role.status === PersonRoleStatus.RUNNING_FOR ? 'Running' : 'Previously Ran'} for{' '}
                {role.primaryCountryCode} Senate in {role.primaryState}
              </span>
              {politicalAffiliationCategoryText}
            </>
          )
        default:
          return gracefullyError({
            msg: `Can only use FormattedRoleWithinContext if we pass a role we know how to deal with explicitly, received ${role.roleCategory!} for ${
              person.id
            }`,
            fallback: role.title,
            context: { extra: { role, person } },
          })
      }
    case PersonRoleStatus.HELD:
      switch (role.roleCategory) {
        case 'PRESIDENT':
        case 'VICE_PRESIDENT':
          return (
            <>
              <span
                className={classNames(
                  'font-light',
                  color === 'light' ? 'text-gray-100' : 'text-gray-700',
                )}
              >
                {role.roleCategory === PersonRoleCategory.VICE_PRESIDENT ? 'Vice ' : ''}President of{' '}
              </span>
              <span className="font-semibold">{formattedCountryCode(role.primaryCountryCode)}</span>
              {politicalAffiliationCategoryText}
            </>
          )
        case 'CONGRESS':
          return (
            <>
              <span
                className={classNames(
                  'font-light',
                  color === 'light' ? 'text-gray-100' : 'text-gray-700',
                )}
              >
                Rep from{' '}
              </span>
              <span className="font-semibold">
                {formattedState({
                  state: role.primaryState,
                  countryCode: role.primaryCountryCode,
                })}
              </span>
              {politicalAffiliationCategoryText}
            </>
          )
        case 'SENATE':
          return (
            <>
              <span
                className={classNames(
                  'font-light',
                  color === 'light' ? 'text-gray-100' : 'text-gray-700',
                )}
              >
                Senator from{' '}
              </span>
              <span className="font-semibold">
                {formattedState({
                  state: role.primaryState,
                  countryCode: role.primaryCountryCode,
                })}
              </span>
              {politicalAffiliationCategoryText}
            </>
          )
      }
  }
  return role.title
}
